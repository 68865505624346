import React from 'react';
import img from "../static/myOwn.png"
import c from "./imageStyle.module.css"
const WebImage = () => {
    return (
      
            <img src={img} alt='img' className={c.container}></img>
        
        
        
    );
};

export default WebImage;
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./ui/Header";
import Footer from "./ui/buttons/Footer";
import Home from "./pages/home";
import About from "./pages/about";
import Mail from "./pages/contact";
import Contact from "./pages/contact";
import { useMediaQuery } from "react-responsive";
import MobileHeader from "./ui/mobile/mobileHeader";

function App() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 800px)",
  });

  const isTablet = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });

  const isPortrait = useMediaQuery({
    query: "(orientation: portrait)",
  });

  const isRetina = useMediaQuery({
    query: "(max-resolution: 300dpi)",
  });
  return (
    <div className="App">
      <div className="container">
        <Header />
        <Home />
        <About />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;

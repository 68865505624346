import React from 'react';
import btn from './Button.module.css'
const Button = ({name}) => {
    return (
        <button className={btn.btn}>
            {name}
        </button>
    );
};

export default Button;
import React from 'react';
import myin from "./MyInput.module.css"
const MyInput = (props) => {
    return (
        <input  className={myin.myInput} {...props}>
            
        </input>
    );
};

export default MyInput;
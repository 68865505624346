import React, { useRef, useState } from "react";
import MyInput from "../ui/inputs/MyInput";
import TextArea from "../ui/inputs/TextArea";
import SendButton from "../ui/buttons/sendButton";
import Button from "../ui/buttons/Button";
import Contact from "../pages/contact";
import ContactBtn from "../ui/buttons/contactBtn";

import ReCAPTCHA from "react-google-recaptcha";

const EmailForm = () => {
  const captchaRef = useRef(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [res, setRes] = useState("");
  const [error, setError] = useState("");

  const [post, isPOst] = useState(false);

  let handleSubmit = async (e) => {
    setRes("");

    e.preventDefault();

    const token = await captchaRef.current.executeAsync();

    
    if (!token) {
      setError("You must confirm you are not a robot");
      return;
    } else {
      setError("");
    }

    isPOst(true);
    try {
      let res = await fetch("https://bogdan-kobylchak.onrender.com/sendMail", {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          sender: email,
          body: message,
          subject: name,
        }),
      });
      let resJson = await res.json();
      console.log(res.status);
      if (res.status === 200) {
        setName("");
        setEmail("");
        setMessage("");
        isPOst(false);
        setRes("Success!");
        setError("");
        captchaRef.current.reset();
      } else {
        setRes(resJson.message);
        isPOst(false);
      }
    } catch (err) {
      console.log(JSON.stringify(err));
    }
  };

  return (
    <form style={{height: "34.739583333333336vw"}} onSubmit={handleSubmit}>
      <MyInput
        type="text"
        value={name}
        placeholder="Your Name"
        onChange={(e) => setName(e.target.value)}
      />
      <MyInput
        type="email"
        value={email}
        placeholder="Your Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextArea
        type="text"
        value={message}
        placeholder="Your Message"
        onChange={(e) => setMessage(e.target.value)}
      />
      {/* <SendButton type="submit" >Contact</SendButton> */}

      <ReCAPTCHA
         
        theme="dark"
        size="invisible"
        ref={captchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_APP_KEY_V3}
      />
      
      {post ? (
        <SendButton disabled>Processing</SendButton>
      ) : (
        <SendButton type="submit">Contact</SendButton>
      )}
      {error ? <p style={{ color: "red" }}>{error}</p> : null}
      {res ? <p style={{ color: "#F3F0E8" }}>{res}</p> : null}
      {/* {post ? <p>Processing...</p> : null} */}
    </form>
  );
};

export default EmailForm;

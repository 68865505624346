import React from "react";
import wr from "./pagesstyle.module.css";
import MyInput from "../ui/inputs/MyInput";
import TextArea from "../ui/inputs/TextArea";
import ContactBtn from "../ui/buttons/contactBtn";

import f from "../ui/buttons/Footer.module.css";
import EmailForm from "../components/EmailForm";

const Contact = () => {
  return (
    <div className={wr.contact__container}>
      <div className={wr.id_home} id="contact"></div>

      <div className={wr.contact__header}>
        <h4>CONTACT ME</h4>
      </div>
      <div className={wr.contact__container__wrapper}>
        <div className={wr.contact__left}>
          <div className={wr.mail__icon}>
            <div className={wr.href__container}>
              <a href="mailto:bogdan.kobylchak@gmail.com">
                <span className={f.iconM}></span>{" "}
              </a>
              <p>bogdan.kobylchak@gmail.com</p>
            </div>
            <div className={wr.href__container}>
              <a href="https://t.me/nonzeq">
                {" "}
                <span className={f.iconT}></span>{" "}
              </a>
              <p>https://t.me/nonzeq</p>
            </div>
          </div>
        </div>

        <div className={wr.contact__right}>
          {/*             
            <MyInput placeholder="Your Name*"/>
            
            <MyInput placeholder="Your Email*" type="email"/>

            <TextArea/>

            
            <ContactBtn props={"Send Message"}/>
       */}



          <EmailForm/>


          
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from 'react';
import f from './Footer.module.css';
const Footer = () => {
    return (
        
        <div className={f.footer}>
            <div className={f.icon__footer}>
            <a href='https://t.me/nonzeq'>  <span   className={f.iconT} ></span>   </a>
            <a href="mailto:bogdan.kobylchak@gmail.com"> <span className={f.iconM}></span> </a>
             <a href='https://github.com/Nonzeq'> <span className={f.iconG}></span>  </a>
            </div>
            

           
             
           
            

        </div>
    );
};

export default Footer;


import React from "react";
import header from "./header.module.css";
import Button from "./buttons/Button";
import pdf from "../static/resume_bogdan_kobylchak.pdf"
import { useMediaQuery } from "react-responsive";
const Header = () => {

  const isMobile = useMediaQuery({
    query: "(max-width: 786px)",
  });
  
  return (
    
      <div className={header.navbar__header}>
        <div className={header.header__block_left}>
          <div className={header.title_name}>B.K.</div>
        </div>
        <div className={header.header__block_rigth}>
          <div div className={header.menu}>
          <a  className={header.header__link} href="#home">Home</a>
            <a className={header.header__link} href="#about" >About</a>
            <a className={header.header__link} href="#contact">Contact</a>
          </div>
            
          {isMobile ? null :
          <a href={pdf} download="resume_bogdan_kobylchak"> <Button name={"Download CV"}/>  </a>
        }
          
              
        </div>
      </div>
    
  );
};

export default Header;

import React from 'react';
import contacnBtn from './Button.module.css'
const ContactBtn = ({ children, ...props }) => {
    return (
        <button  {...props} className={contacnBtn.contactBtn}>
            {children}
        </button>
    );
};

export default ContactBtn;
import React from 'react';
import contacnBtn from './Button.module.css'

const SendButton = ({ children, ...props }) => {
    return (
        <button {...props} className={contacnBtn.sendBtn}>
            {children}
        </button>
    );
};

export default SendButton;
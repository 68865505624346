import React from 'react';
import wr from "./pagesstyle.module.css"
import ContactBtn from '../ui/buttons/contactBtn';
import WebImage from '../ui/webImage';
    



const Home = () => {
    return (
        
        <div className={wr.page__wrapper}>

            <div className={wr.home__wrapper}>

                      
            <div className={wr.id_home} id="home"></div>
            <div className={wr.title__container}>


                <div className={wr.text__container}> <h1>BOHDAN</h1>
                <h1>KOBYLCHAK</h1>
                <h3>Trainee Java Developer</h3>

                
                <a href='#contact'>

                <ContactBtn>Contact</ContactBtn>
                </a>
                
                </div>
               
                <div className={wr.img__container}> <WebImage/> </div>

            </div>



            </div>

      
            
    
            
        </div>
    );
};

export default Home;
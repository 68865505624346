import React from "react";
import wr from "./pagesstyle.module.css";
import Button from "../ui/buttons/Button";
import pdf from "../static/resume_bogdan_kobylchak.pdf"
const About = () => {
  return (
    <div className={wr.about__container}>
      <div className={wr.id_home} id="about"></div>
      <div className={wr.about__header}>
        <h4>ABOUT ME</h4>
      </div>

      <div className={wr.about__text}>
        <p>
          I`m a Java developer with a passion for programming and solving
          complex problems. <tr />
          And I`m looking for entry-level positions. I`m eager to be part of a
          team where I can develop my skills and use them to achieve common
          goals.
        </p>
      </div>

      <div className={wr.about__header}>
        <h4>SKILLS</h4>
      </div>

      <div className={wr.skills__wrapper}>

        <div className={wr.skills__container}>
          <h4>
            <div className={wr.skills__wrapper_dash}>// </div>Hard Skills
          </h4>

          <ul>Java</ul>
          <ul>Spring Boot</ul>
          <ul>SQL</ul>
          <ul>PostgresSQL</ul>
          <ul>Java Script</ul>
          <ul>HTML/CSS</ul>
          <ul>React</ul>
        </div>

        <div className={wr.skills__container}>
          <h4>
            <div className={wr.skills__wrapper_dash}>// </div>Soft Skills
          </h4>

          <ul>Teamwork</ul>
          <ul>Critical thinking</ul>
          <ul>Problem-solving</ul>
          <ul>Time management</ul>
          <ul>Stress management</ul>
        </div>
        
        <div className={wr.skills__container}>
          <h4>
            <div className={wr.skills__wrapper_dash}>// </div>Language
          </h4>

          <ul>Ukrainian - native</ul>
          <ul>English - Intermediate</ul>
        </div>
      </div>
      <div className={wr.under__container}>
        <p>To view the projects, please</p>
        <a href="https://github.com/Nonzeq">
          <Button name={"Check My Github"} />
        </a>

        <p>or</p>
        <a></a>
        <a href={pdf} download="resume_bogdan_kobylchak"> <Button name={"Download CV"}/></a>
      </div>
    </div>
  );
};

export default About;

import React from 'react';
import myin from "./MyInput.module.css"
const TextArea = (props) => {
    return (
        <textarea {...props} className={myin.myTextArea}>

        </textarea>
    );
};

export default TextArea;